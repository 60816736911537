<template>
  <validation-observer ref="validationObserver" v-slot="{ handleSubmit }">
    <form class="form">
      <div class="card card-custom">
        <div class="card-header border-0 pt-6 pb-0">
          <div class="card-title align-items-start flex-column">
            <h3 class="card-label font-weight-bolder">
              {{ $t("Contacts") }}
            </h3>
            <span class="text-muted font-weight-bold font-size-sm mt-1">{{
              $t("Update your company's contacts")
            }}</span>
          </div>
          <div class="card-toolbar">
            <button class="btn btn-success mr-2" :class="{ 'spinner spinner-light spinner-right': isSaving }"
              @click.prevent="handleSubmit(save)">
              {{ $t("Save Changes") }}
            </button>
          </div>
        </div>
        <div class="card-body detail">
          <template v-if="legalPerson.country_of_establishment == 'IT'">
            <validation-provider v-slot="{ errors }" vid="email_pec" rules="email" :name="$t('PEC')">
              <h6>{{ $t("PEC") }}:</h6>
              <euro-input v-model="pec" type="email" :error-messages="errors" class-name="w-100"></euro-input>
            </validation-provider>
            <div class="separator separator-dashed my-5"></div>
          </template>

          <h6 class="mt-3">{{ $t("Email Contacts") }}:</h6>
          <multi-emails-input :key="`emails-${multiEmailsInputKey}`" v-model="emailsContactsCpy" :options="EMAIL_KINDS"
            @updated="handleSubmit(save)"></multi-emails-input>

          <div class="separator separator-dashed my-5"></div>

          <h6>{{ $t("Telephone Contacts") }}:</h6>
          <multi-telephone-address-input :key="`telephones-${multiTelephonesInputKey}`" v-model="telContactsCpy"
            :options="TELEPHONE_KINDS" :addresses="ADDRESSES" @updated="updateMain"></multi-telephone-address-input>

          <div class="separator separator-dashed my-5"></div>

          <h6 class="mt-3">{{ $t("Social Contacts") }}:</h6>
          <validation-provider v-slot="{ errors }" vid="social_contacts">
            <multi-social-input :key="`socials-${multiSocialInputKey}`" v-model="socialContactsCpy"
              :options="SOCIAL_KINDS" :error-messages="errors"></multi-social-input>
          </validation-provider>
        </div>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import _ from "lodash";
import CompanyService from "@/core/services/company/company.service";
import CompanyTelephoneService from "@/core/services/company/company-telephone.service";
import CompanyEmailService from "@/core/services/company/company-email.service";
import CompanySocialService from "@/core/services/company/company-social.service";
import icons from "@/core/config/icons.js";
import { unsavedChangesSwal, backendErrorSwal } from "@/core/helpers/swal";
import { successToast } from "@/core/helpers";
export default {
  props: {
    legalPerson: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      icons,
      originalTelContacts: null,
      originalMailContacts: null,
      originalSocialContacts: null,
      telContactsCpy: [],
      emailsContactsCpy: [],
      socialContactsCpy: [],
      apiErrors: null,
      isSaving: false,
      multiTelephonesInputKey: 0,
      multiEmailsInputKey: 0,
      multiSocialInputKey: 0,
      TELEPHONE_KINDS: [],
      EMAIL_KINDS: [],
      SOCIAL_KINDS: [],
      pec: this?.legalPerson?.email_pec ?? "",
    };
  },

  computed: {
    ADDRESSES() {
      return this.legalPerson.companyaddress_set.map((el) => ({ text: el.address.formatted_address, value: el.id }));
    },

    phonesWithCompany() {
      return this.telContactsCpy.filter((el) => el.number != '').map((el) => ({ ...el, company: this.legalPerson.id }));
    },

    emailsWithCompany() {
      return this.emailsContactsCpy.filter((el) => el.email != '').map((el) => ({ ...el, company: this.legalPerson.id }));
    },

    socialWithCompany() {
      return this.socialContactsCpy.map((el) => ({ ...el, company: this.legalPerson.id }));
    },

    requestBody() {
      // name is required for the api.
      return {
        id: this.legalPerson.id,
        name: this.legalPerson.name,
        telcontactscompany_set: this.phonesWithCompany,
        emailcompany_set: this.emailsWithCompany,
        socialcompany_set: this.socialWithCompany,
        email_pec: this.pec,
      };
    },
  },

  watch: {
    apiErrors: {
      deep: true,
      handler(val) {
        const formObs = this.$refs.validationObserver;
        let newErrors = {
          social_contacts: [...(val.socialcompany_set ?? [])],
        };

        if (val?.telcontactscompany_set) {
          val.telcontactscompany_set.forEach((el, idx) => {
            el.number ? (newErrors[`phone[${idx}]`] = el.number[0]) : {};
            el.company_address ? (newErrors[`company_address[${idx}]`] = el.company_address[0]) : {};
          });
        }

        if (val?.emailcompany_set) {
          val.emailcompany_set.forEach((el, idx) => {
            el.email ? (newErrors[`email[${idx}]`] = el.email[0]) : {};
          });
        }

        formObs.setErrors({ ...newErrors });
      },
    },

    legalPerson: {
      deep: true,
      handler() {
        this.cancel();
      },
    },
  },

  created() {
    this.init();
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Legal person"), route: { name: "manage-legal-person" } },
      { title: this.$t("Contacts") },
    ]);
  },

  methods: {
    async updateMain(tel) {
      const exMain = this.telContactsCpy.find((el) => el.main && el.number !== tel.number);
      const phone = this.telContactsCpy.find((el) => el.id === tel.id);
      if (exMain) exMain.main = false;
      if (phone) phone.main = true;
      await this.save();
      this.resetTelContacts();
    },
    async save() {
      this.isSaving = true;
      try {
        await CompanyService.update(this.requestBody, this.requestBody.id);
        this.$emit("updated");
        await successToast("Contacts saved");
      } catch (err) {
        if (err?.response?.status === 400) {
          this.apiErrors = err.response.data;
        } else {
          await backendErrorSwal();
        }
      } finally {
        this.isSaving = false;
      }
    },

    cancel() {
      this.resetTelContacts();
      this.resetEmailContacts();
      this.resetSocialContacts();

      // Workaround to reset the multi-telephones, mails and socials component.
      this.multiTelephonesInputKey++;
      this.multiEmailsInputKey++;
      this.multiSocialInputKey++;
    },

    resetTelContacts() {
      this.telContactsCpy = _.cloneDeep(this.legalPerson.telcontactscompany_set);
      this.originalTelContacts = _.cloneDeep(this.telContactsCpy);
    },

    resetEmailContacts() {
      this.emailsContactsCpy = _.cloneDeep(this.legalPerson.emailcompany_set);
      this.originalMailContacts = _.cloneDeep(this.emailsContactsCpy);
    },

    resetSocialContacts() {
      this.socialContactsCpy = _.cloneDeep(this.legalPerson.socialcompany_set);
      this.originalSocialContacts = _.cloneDeep(this.socialContactsCpy);
    },

    async init() {
      try {
        this.resetTelContacts();
        this.resetEmailContacts();
        this.resetSocialContacts();

        const [phoneKinds, mailKinds, socialKinds] = await Promise.all([
          CompanyTelephoneService.getKinds(),
          CompanyEmailService.getKinds(),
          CompanySocialService.getKinds(),
        ]);

        this.TELEPHONE_KINDS = phoneKinds.map((el) => ({ value: el.value, text: el.display_name }));
        this.EMAIL_KINDS = mailKinds.map((el) => ({ value: el.value, text: el.display_name }));
        this.SOCIAL_KINDS = socialKinds.map((el) => ({ value: el.value, text: el.display_name }));
      } catch (err) {
        await backendErrorSwal();
        console.log("failed to init", err);
      }
    },
  },

  async beforeRouteLeave(__, ___, next) {
    const telsToCompare = this.telContactsCpy.map(num => ({ ...num, number: num.number.replaceAll(" ", "") }))
    const emailsToCompare = this.emailsContactsCpy.filter((el) => el.email != '')
    if (
      !_.isEqual(telsToCompare, this.originalTelContacts) ||
      !_.isEqual(emailsToCompare, this.originalMailContacts) ||
      !_.isEqual(this.socialContactsCpy, this.originalSocialContacts)
    ) {
      const res = await unsavedChangesSwal();
      if (res.isConfirmed) await this.save();
      if (res.isDismissed) return;
    }
    next();
  },
};
</script>

<style scoped>
::v-deep .row {
  justify-content: space-between;
}

.pec-gap {
  gap: 3rem;
}
</style>
